<template>
  <b-card>
    <!-- form -->
    <validation-observer
      ref="passForm"
    >
      <b-form
        @submit.prevent="updatePass"
      >
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Выберите тип подтверждения пароля"
            >
              <div class="demo-inline-spacing">
                <b-form-radio
                  v-model="selected"
                  value="A"
                >
                  По старому паролю
                </b-form-radio>
                <b-form-radio
                  v-model="selected"
                  value="B"
                >
                  По коду из CMC
                </b-form-radio>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="selected === 'B'"
          class="mb-1"
        >
          <b-col
            v-if="!isSendCode"
            md="12"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="mt-1 mr-1"
              @click="sendCode"
            >
              Запросить код в SMS
            </b-button>
          </b-col>
          <b-col
            v-if="isSendCode"
            md="6"
          >
            <b-form-group
              v-if="!isLoadingLocal"
              label="Kод из SMS"
              label-for="account-code"
            >
              <validation-provider
                #default="{ errors }"
                name="    "
                :rules="codeRules"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-code"
                    v-model="code"
                    placeholder="Введите код из SMS"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div
              v-if="isLoadingLocal"
              class="skeleton-form-group"
            >
              <b-skeleton width="20%" />
              <b-skeleton type="input" />
            </div>
          </b-col>
          <b-col
            v-if="isSendCode"
            md="6"
          >
            <b-button
              v-if="!isLoadingLocal"
              class="mt-2"
              variant="outline-primary"
              block
              :disabled="!isReSendCode"
              @click="reSendCode"
            >
              <span class="mr-2">Запросить пароль</span>
              <feather-icon
                v-if="currentTime !== 0"
                icon="ClockIcon"
                size="16"
              />
              <span
                v-if="currentTime !== 0"
                style="width: 45px; margin-left: 5px;"
              > {{ currentTime }} сек</span>
            </b-button>
            <b-skeleton
              v-if="isLoadingLocal"
              class="mt-2 w-100"
              type="button"
            />
          </b-col>
        </b-row>
        <b-row v-if="selected === 'A'">
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              v-if="!isLoadingLocal"
              label="Старый пароль"
              label-for="account-old-password"
            >
              <validation-provider
                #default="{ errors }"
                name="   "
                :rules="oldPasswordRules"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueOld"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    placeholder="Введите старый пароль"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div
              v-if="isLoadingLocal"
              class="skeleton-form-group"
            >
              <b-skeleton width="20%" />
              <b-skeleton type="input" />
            </div>
          </b-col>
        <!--/ old password -->
        </b-row>

        <b-row v-if="!isLoadingLocal">
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              label="Новый пароль"
            >
              <validation-provider
                #default="{ errors }"
                name=" "
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="Введите новый пароль"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              label="Повторно новый пароль"
            >
              <validation-provider
                #default="{ errors }"
                name="  "
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="retypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="Повторно введите новый пароль"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row
          v-if="isLoadingLocal"
          class="mt-1"
        >
          <b-col
            v-for="n in 2"
            :key="n"
            class="mb-1"
            sm="6"
          >
            <div class="skeleton-form-group">
              <b-skeleton width="20%" />
              <b-skeleton type="input" />
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click="updatePass"
            >
              Сохранить изменения
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mt-1"
              @click="reset"
            >
              Очистить
            </b-button>
          </b-col>
        <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  ValidationProvider, ValidationObserver, localize,
} from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import { required } from '@validations'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BFormRadio, BSkeleton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUserId } from '@/application/userService'
import userRepository from '@/repository/userRepository'

localize('ru', ru)

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormRadio,
    BSkeleton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoadingLocal: false,
      selected: 'A',
      isSendCode: false,
      code: '',
      passwordValueOld: '',
      newPasswordValue: '',
      retypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',

      isReSendCode: false,
      currentTime: 29,
      timer: null,

      // validation rules
      required,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    oldPasswordRules() {
      return this.selected === 'A' ? 'required' : ''
    },
    codeRules() {
      return this.selected === 'B' ? 'required' : ''
    },
  },
  watch: {
    currentTime(time) {
      if (time === 0) {
        this.stopTimer()
        this.isReSendCode = true
      }
    },
  },
  destroyed() {
    this.stopTimer()
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    reset() {
      this.code = ''
      this.passwordValueOld = ''
      this.newPasswordValue = ''
      this.retypePassword = ''
    },
    startTimer() {
      this.timer = setInterval(() => {
        // eslint-disable-next-line no-plusplus
        this.currentTime--
      }, 1000)
    },
    stopTimer() {
      clearTimeout(this.timer)
    },
    async reSendCode() {
      if (this.isReSendCode) {
        return
      }
      this.currentTime = 30
      await this.sendCode()
    },
    async sendCode() {
      try {
        this.isSendCode = true
        this.isReSendCode = true
        const userId = getUserId()
        await userRepository.sendPasswordCode(userId)
        this.startTimer()
      } catch (error) {
        this.$store.commit('app/SET_ERROR', error)
      }
    },
    validate() {
      if (String(this.newPasswordValue) === String(this.passwordValueOld) && this.selected === 'A') {
        throw new Error('Новый пароль и старый совпадают')
      }
      if (String(this.newPasswordValue) !== String(this.retypePassword)) {
        throw new Error('Пароли не совпадают')
      }
      if (!this.isSendCode && this.selected === 'B') {
        throw new Error('Необходимо отправить код подтверждения на телефон')
      }
    },
    async updatePass() {
      try {
        this.isLoadingLocal = true
        const success = await this.$refs.passForm.validate()
        if (success) {
          this.validate()

          const param = new FormData()
          param.append('newPasswordValue', this.newPasswordValue)
          param.append('retypePassword', this.retypePassword)
          if (this.selected === 'A') {
            param.append('passwordValueOld', this.passwordValueOld)
          }
          if (this.selected === 'B') {
            param.append('code', this.code)
          }

          const userId = getUserId()
          const result = await userRepository.updateUserPassword(userId, param)
          if (!result) {
            throw new Error('Ошибка обновления данных')
          }
          this.reset()
          this.$store.commit('app/SET_MESSAGE', 'Данные аккаунта успешно обновлены')
        }
        this.isLoadingLocal = false
      } catch (error) {
        this.isLoadingLocal = false
        this.$store.commit('app/SET_ERROR', error)
      }
    },
  },
}
</script>

<style scoped>
.demo-inline-spacing  > * {
  margin-top: 0.3rem;
}
.skeleton-form-group .b-skeleton-text {
  margin-bottom: 0.45rem;
}
</style>
