<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <b-tab active>
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Общие</span>
      </template>

      <account-setting-general
        :motivation-bonus="motivation.general"
        :user-motivation="userMotivation.general"
        @refresh="getData"
      />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Изменить пароль</span>
      </template>

      <account-setting-password />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">О компании</span>
      </template>

      <account-setting-company
        :motivation-bonus="motivation.company"
        :user-motivation="userMotivation.company"
        @refresh="getData"
      />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Уведомления</span>
      </template>

      <account-setting-notification
        :notification-data="{}"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { getUserId } from '@/application/userService'
import usersMotivationRepository from '@/repository/usersMotivationRepository'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingCompany from './AccountSettingCompany.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingCompany,
    AccountSettingNotification,
  },
  data() {
    return {
      isLoading: false,
      options: {},
      motivation: {},
      userMotivation: {},
    }
  },
  async mounted() {
    await this.getData()
  },
  methods: {
    async getData() {
      try {
        this.isLoading = true
        const userId = getUserId()
        const { motivation, userMotivation } = await usersMotivationRepository.getDataUserId(userId)
        this.motivation = motivation || {}
        this.userMotivation = userMotivation || {}
      } catch (error) {
        this.isLoading = false
        this.$store.commit('app/SET_ERROR', error)
      }
    },
  },
}
</script>
