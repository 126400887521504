<template>
  <b-card>
    <b-row
      v-for="item in optionsLocal"
      :key="item.categoryId"
    >
      <h6 class="section-label mx-1 mb-2">
        {{ item.category }}
      </h6>
      <b-col
        v-for="element in item.elements"
        :key="element.id"

        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          v-model="element.value"
          :value="1"
          :unchecked-value="0"
          switch
          inline
        >
          <span>{{ element.title }}</span>
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mr-1"
          @click="saveForm"
        >
          Сохранить изменения
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          type="reset"
          class="mt-2"
          @click.prevent="resetForm"
        >
          Сбросить
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton, BRow, BCol, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUserId } from '@/application/userService'
import notificationRepository from '@/repository/notificationRepository'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    notificationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoadingLocal: true,
      optionsLocal: [],
      localOptions: JSON.parse(JSON.stringify(this.notificationData)),
    }
  },
  async mounted() {
    await this.getData()
  },
  methods: {
    async resetForm() {
      await this.getData()
    },
    async getData() {
      try {
        this.isLoadingLocal = true
        const userId = getUserId()
        const list = (await notificationRepository.getList(userId, { sord: 'asc', sidx: 'elementId' })) || []
        const optionsLocal = []
        if ((list || []).length > 0) {
          list.forEach(el => {
            const search = optionsLocal.find(x => String(x.categoryId) === String(el.categoryId))
            if (!search) {
              optionsLocal.push({
                categoryId: el.categoryId,
                category: el.category && el.category.title ? el.category.title : '',
                elements: [{
                  id: el.id,
                  value: el.value,
                  title: el.element && el.element.title ? el.element.title : '',
                }],
              })
            } else {
              const searchElement = search.elements.find(x => String(x.id) === String(el.id))
              if (!searchElement) {
                search.elements.push({
                  id: el.id,
                  value: el.value,
                  title: el.element && el.element.title ? el.element.title : '',
                })
              }
            }
          })
        }

        this.optionsLocal = optionsLocal
        this.isLoadingLocal = false
      } catch (error) {
        this.optionsLocal = []
        this.isLoadingLocal = false
        this.$store.commit('app/SET_ERROR', error)
      }
    },
    async saveForm() {
      try {
        this.isLoadingLocal = true
        const userId = getUserId()

        const userElements = []
        this.optionsLocal.forEach(el => {
          if ((el.elements || []).length > 0) {
            el.elements.forEach(x => {
              userElements.push(x)
            })
          }
        })

        const param = new FormData()
        param.append('userElements', JSON.stringify(userElements))

        const notifications = await notificationRepository.update(userId, param)
        if (!notifications) {
          throw new Error('Ошибка формирования данных')
        }

        this.isLoadingLocal = false
        this.$store.commit('app/SET_MESSAGE', 'Данные аккаунта успешно обновлены')
      } catch (error) {
        this.isLoadingLocal = false
        this.$store.commit('app/SET_ERROR', error)
      }
    },
  },
}
</script>
