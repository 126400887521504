import conect from './api'

const { axiosIns } = conect()

export default {
  async list(...args) {
    const { error, result } = (await axiosIns.get('/users-company-type', ...args)).data
    if (error) throw error

    return result
  },
}
