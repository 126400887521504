import conect from './api'

const { axiosIns } = conect()

export default {
  async getList(userId, params) {
    const { error, result } = (await axiosIns.get(`/notification/user/${userId}`, { params })).data
    if (error) throw error

    return result
  },
  async update(userId, ...args) {
    const { error, result } = (await axiosIns.put(`/notification/user/${userId}`, ...args)).data
    if (error) throw error

    return result
  },
}
