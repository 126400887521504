<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row v-if="!isLoadingLocal">
        <b-col md="6">
          <b-form-group
            label-for="name"
            label="Название магазина/организации"
          >
            <b-form-input
              id="name"
              v-model="optionsLocal.name"
              placeholder="введите название"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label-for="address"
            label="Адрес магазина/организации"
          >
            <b-form-input
              id="address"
              v-model="optionsLocal.address"
              placeholder="введите адрес"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label-for="countryList"
            label="Тип организации"
          >
            <v-select
              id="countryList"
              v-model="optionsLocal.companyTypeId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              placeholder="выберите тип организации"
              :reduce="list => list.id"
              :options="companyType"
            >
              <template slot="no-options">
                Введите для поиска типа организации
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label-for="taxNumber"
            label="ИНН организации"
          >
            <b-form-input
              id="taxNumber"
              v-model="optionsLocal.taxNumber"
              placeholder="введите ИНН организации"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        v-if="isLoadingLocal"
      >
        <b-col
          v-for="n in 4"
          :key="n"
          class="mb-1"
          sm="6"
        >
          <div class="skeleton-form-group">
            <b-skeleton width="20%" />
            <b-skeleton type="input" />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-75"
        >
          <b-alert
            v-if="motivationBonusLocal && (!userMotivationLocal || (userMotivationLocal && !userMotivationLocal.hide) || (userMotivationLocal && userMotivationLocal.hide && String(userMotivationLocal.hide) !== '1'))"
            :dismissible="userMotivationLocal && userMotivationLocal.status && userMotivationLocal.status !== 'create'"
            :show="alertShow"
            :variant="variant"
            class="mb-50"
            @dismissed="alertDismissed"
          >
            <h4
              v-if="variant === 'warning'"
              class="alert-heading"
            >
              Заполните все поля, получите {{ motivationBonusLocal }} бонусов и возможность зарабатывать бонусы за продажи.
            </h4>
            <h4
              v-if="userMotivationLocal && userMotivationLocal.status && userMotivationLocal.status === 'denial'"
              class="alert-heading"
            >
              Для получения бонусов необходимо скоректировать данные этого раздела!
            </h4>
            <h4
              v-if="userMotivationLocal && userMotivationLocal.status && userMotivationLocal.status === 'forbidden'"
              class="alert-heading"
            >
              Поставлен запрет на начисление бонусов по заполнению этого раздела!
            </h4>
            <h4
              v-if="variant === 'success' && userMotivationLocal && userMotivationLocal.status && userMotivationLocal.status === 'accept'"
              class="alert-heading"
            >
              Поздравляем, Вам начисленны бонусы
            </h4>
            <h4
              v-if="variant === 'success' && userMotivationLocal && userMotivationLocal.status && userMotivationLocal.status === 'create'"
              class="alert-heading"
            >
              Заявка на модерацию данных отправлена
            </h4>
            <div
              v-if="variant === 'warning'"
              class="alert-body"
            >
              <div v-if="!optionsLocal || !optionsLocal.name">
                Заполните название компании/магазина.
              </div>
              <div v-if="!optionsLocal || !optionsLocal.address">
                Поле фамилия не заполнено.
              </div>
              <div v-if="!optionsLocal || !optionsLocal.companyTypeId">
                Не выбран тип компании.
              </div>
              <div v-if="!optionsLocal || !optionsLocal.taxNumber">
                Поле ИНН не заполнено.
              </div>
            </div>

            <div
              v-if="variant === 'danger'"
              class="alert-body"
            >
              <div v-if="userMotivationLocal && userMotivationLocal.comments && String(userMotivationLocal.comments).trim().length > 0">
                {{ userMotivationLocal.comments }} <span v-if="userMotivationLocal && userMotivationLocal.status && userMotivationLocal.status === 'denial'">Введите более коректные данные для получения  {{ motivationBonusLocal }} бонусов.</span>
              </div>
              <div v-else>
                Модератор не одобрил заполнение данных. Введите более коректные данные для получения  {{ motivationBonusLocal }} бонусов.
              </div>
            </div>

            <div
              v-if="variant === 'success' && userMotivationLocal && userMotivationLocal.status && userMotivationLocal.status === 'accept'"
              class="alert-body"
            >
              <div>
                Спасибо за заполненый профиль, Вам начисленны бонусы. <b-link
                  v-if="userMotivationLocal && userMotivationLocal.depositId"
                  :href="`/deposits/${userMotivationLocal.depositId}`"
                >
                  Подробнее
                </b-link>
              </div>
            </div>

          </b-alert>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="saveForm"
          >
            Сохранить изменения
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1"
            variant="outline-secondary"
            @click.prevent="resetForm"
          >
            Сбросить
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BLink, BSkeleton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import usersMotivationRepository from '@/repository/usersMotivationRepository'
import { getUserId } from '@/application/userService'
import userCompanyRepository from '@/repository/userCompanyRepository'
import usersCompanyTypeRepository from '@/repository/usersCompanyTypeRepository'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    vSelect,
    BLink,
    BSkeleton,
  },
  directives: {
    Ripple,
  },
  props: {
    motivationBonus: {
      type: String,
      default: null,
    },
    userMotivation: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoadingLocal: false,
      optionsLocal: {},
      alertShow: true,
      companyType: [],
    }
  },
  computed: {
    variant() {
      if (!this.userMotivationLocal) {
        return 'warning'
      } if (this.userMotivationLocal && this.userMotivationLocal.status && (this.userMotivationLocal.status === 'denial' || this.userMotivationLocal.status === 'forbidden')) {
        return 'danger'
      }
      return 'success'
    },
    motivationBonusLocal() {
      return this.motivationBonus
    },
    userMotivationLocal() {
      return this.userMotivation
    },
  },
  async mounted() {
    await Promise.all([
      this.getData(),
      this.getCompanyType(),
    ])
  },
  methods: {
    async resetForm() {
      await this.getData()
    },
    // eslint-disable-next-line consistent-return
    async alertDismissed() {
      try {
        const id = this.userMotivationLocal ? this.userMotivationLocal.id : null
        if (!id) {
          return false
        }
        const param = new FormData()
        param.append('hide', '1')
        await usersMotivationRepository.update(id, param)

        return true
      } catch (error) {
        this.$store.commit('app/SET_ERROR', error)
      }
    },
    async saveForm() {
      try {
        this.isLoadingLocal = true
        const userId = getUserId()
        const {
          companyTypeId, taxNumber, name, address,
        } = this.optionsLocal

        const param = new FormData()
        param.append('companyTypeId', companyTypeId)
        param.append('taxNumber', taxNumber)
        param.append('name', name)
        param.append('address', address)
        // param.append('departmentStore', this.optionsLocal.departmentStore)

        const company = await userCompanyRepository.update(userId, param)
        if (!company) {
          throw new Error('Ошибка формирования данных')
        }
        this.$emit('refresh')
        this.optionsLocal = JSON.parse(JSON.stringify(company))

        this.isLoadingLocal = false
        this.$store.commit('app/SET_MESSAGE', 'Данные аккаунта успешно обновлены')
      } catch (error) {
        this.isLoadingLocal = false
        this.$store.commit('app/SET_ERROR', error)
      }
    },
    async getData() {
      try {
        this.isLoadingLocal = true
        const userId = getUserId()
        this.optionsLocal = (await userCompanyRepository.getElement(userId)) || {}
        this.isLoadingLocal = false
      } catch (error) {
        this.optionsLocal = {}
        this.isLoadingLocal = false
        this.$store.commit('app/SET_ERROR', error)
      }
    },
    async getCompanyType() {
      try {
        this.companyType = await usersCompanyTypeRepository.list({ active: 1 })
      } catch (error) {
        this.companyType = []
        throw error
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
.skeleton-form-group .b-skeleton-text {
  margin-bottom: 0.45rem;
}
</style>
