<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside v-if="!isLoadingLocal">
        <b-link @click="$refs.refInputEl.$el.click()">
          <b-avatar
            v-if="!isLoadingLocal && previewEl === null"
            variant="light-primary"
            size="80"
            rounded
          >
            <feather-icon
              icon="UserIcon"
              size="40"
            />
          </b-avatar>
          <b-img
            v-if="!isLoadingLocal && previewEl !== null"
            ref="previewEl1"
            rounded
            :src="previewEl"
            fluid
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-aside v-else>
        <div class="skeleton-avatar">
          <b-skeleton-img
            height="80px"
            width="80px"
          />
        </div>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <div v-if="!isLoadingLocal">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="$refs.refInputEl.$el.click()"
          >
            <span v-if="optionsLocal.avatar === null && fileAvatar === null">Загрузить</span>
            <span v-else>Обновить</span>
          </b-button>
          <b-form-file
            ref="refInputEl"
            v-model="profileFile"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            @input="inputImageRenderer"
          />
          <!--/ upload button -->

          <!-- reset -->
          <b-button
            v-if="fileAvatar !== null"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="mb-75 mr-75"
            @click.prevent="resetAvatar"
          >
            Сбросить
          </b-button>
          <b-button
            v-if="optionsLocal.avatar !== null && fileAvatar === null"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="mb-75 mr-75"
            @click.prevent="clearAvatar"
          >
            Очистить
          </b-button>
          <!--/ reset -->
          <b-card-text>Допустимый формат - JPG или PNG. Максимальный размер 800kB</b-card-text>
        </div>
        <div
          v-if="isLoadingLocal"
          class="skeleton-button-avatar"
        >
          <div style="overflow: hidden;">
            <b-skeleton
              size="sm"
              class="mb-75 mr-75"
              type="button"
            />
            <b-skeleton
              v-if="fileAvatar !== null"
              size="sm"
              class="mb-75 mr-75"
              type="button"
            />
          </div>
          <b-skeleton width="33%" />
        </div>
      </b-media-body>
    </b-media>
    <!--/ media -->
    <validation-observer
      ref="generalForm"
    >
      <!-- form -->
      <b-form class="mt-2">
        <b-row v-if="!isLoadingLocal">
          <b-col sm="6">
            <b-form-group
              label="Имя"
              label-for="account-firstName"
            >
              <b-form-input
                v-model="optionsLocal.firstName"
                placeholder="Введите ваше имя"
                name="firstName"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Фамилия"
              label-for="account-lastName"
            >
              <b-form-input
                v-model="optionsLocal.lastName"
                name="lastName"
                placeholder="Введите вашу фамилию"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Телефон"
              label-for="account-phone"
            >
              <div class="phone-input">
                {{ phone }}
              </div>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              name="пароль"
              rules="email"
            >
              <b-form-group
                label="E-mail"
                label-for="account-e-mail"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  v-model="optionsLocal.email"
                  name="email"
                  placeholder="Введите ваш Email"
                  :state="errors.length > 0 ? false : null"
                />

              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Реферальный код"
              label-for="account-referralCode"
            >
              <div class="phone-input">
                {{ optionsLocal.referralCode }}
              </div>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Реферальная ссылка"
              label-for="account-referralUrl"
            >
              <div class="phone-input">
                {{ referralUrl }}
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="isLoadingLocal"
        >
          <b-col
            v-for="n in 6"
            :key="n"
            class="mb-1"
            sm="6"
          >
            <div class="skeleton-form-group">
              <b-skeleton width="20%" />
              <b-skeleton type="input" />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <!-- alert -->
          <b-col
            cols="12"
            class="mt-75"
          >
            <b-alert
              v-if="motivationBonusLocal && (!userMotivationLocal || (userMotivationLocal && !userMotivationLocal.hide) || (userMotivationLocal && userMotivationLocal.hide && String(userMotivationLocal.hide) !== '1'))"
              :dismissible="userMotivationLocal && userMotivationLocal.status && userMotivationLocal.status !== 'create'"
              :show="alertShow"
              :variant="variant"
              class="mb-50"
              @dismissed="alertDismissed"
            >
              <h4
                v-if="variant === 'warning'"
                class="alert-heading"
              >
                Заполните все поля и получите {{ motivationBonusLocal }} бонусов.
              </h4>
              <h4
                v-if="userMotivationLocal && userMotivationLocal.status && userMotivationLocal.status === 'denial'"
                class="alert-heading"
              >
                Для получения бонусов необходимо скоректировать данные этого раздела!
              </h4>
              <h4
                v-if="userMotivationLocal && userMotivationLocal.status && userMotivationLocal.status === 'forbidden'"
                class="alert-heading"
              >
                Поставлен запрет на начисление бонусов по заполнению этого раздела!
              </h4>
              <h4
                v-if="variant === 'success' && userMotivationLocal && userMotivationLocal.status && userMotivationLocal.status === 'accept'"
                class="alert-heading"
              >
                Поздравляем, Вам начисленны бонусы
              </h4>
              <h4
                v-if="variant === 'success' && userMotivationLocal && userMotivationLocal.status && userMotivationLocal.status === 'create'"
                class="alert-heading"
              >
                Заявка на модерацию данных отправлена
              </h4>
              <div
                v-if="variant === 'warning'"
                class="alert-body"
              >
                <div v-if="!optionsLocal.avatar">
                  Оживите профиль, добавив фото, ваши коллеги быстрее узнают вас и присоединятся.
                </div>
                <div v-if="!optionsLocal.lastName">
                  Поле фамилия не заполнено.
                </div>
                <div v-if="!optionsLocal.email">
                  Поле Email не заполнено.
                </div>
                <div v-if="optionsLocal.email && !optionsLocal.verifEmail">Электронная почта не подтверждена.
                  <b-link class="alert-link">
                    Отправить ссылку на Email-ящик
                  </b-link></div>
              </div>
              <div
                v-if="variant === 'danger'"
                class="alert-body"
              >
                <div v-if="userMotivationLocal && userMotivationLocal.comments && String(userMotivationLocal.comments).trim().length > 0">
                  {{ userMotivationLocal.comments }} <span v-if="userMotivationLocal && userMotivationLocal.status && userMotivationLocal.status === 'denial'">Введите более коректные данные для получения  {{ motivationBonusLocal }} бонусов.</span>
                </div>
                <div v-else>
                  Модератор не одобрил заполнение данных. Введите более коректные данные для получения  {{ motivationBonusLocal }} бонусов.
                </div>
              </div>
              <div
                v-if="variant === 'success' && userMotivationLocal && userMotivationLocal.status && userMotivationLocal.status === 'accept'"
                class="alert-body"
              >
                <div>
                  Спасибо за заполненый профиль, Вам начисленны бонусы. <b-link :href="`/deposits/${userMotivationLocal.depositId}`">
                    Подробнее
                  </b-link>
                </div>
              </div>
            </b-alert>
          </b-col>
          <!--/ alert -->

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click="saveForm"
            >
              Сохранить изменения
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              Сбросить
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import { email } from '@validations'

import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BSkeletonImg, BSkeleton, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { getUserId } from '@/application/userService'
import userRepository from '@/repository/userRepository'
import usersMotivationRepository from '@/repository/usersMotivationRepository'

localize('ru', ru)

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BSkeletonImg,
    BSkeleton,
    BAvatar,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    motivationBonus: {
      type: String,
      default: null,
    },
    userMotivation: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      optionsLocal: {},
      profileFile: null,
      isLoadingLocal: this.isLoading,
      baseURL: new URL(window.location),
      emailState: null,
      // validation rules
      email,
      alertShow: true,
    }
  },
  computed: {
    phone() {
      const { phone } = this.optionsLocal
      return phone ? `+7 (${phone.substring(0, 3)}) ${phone.substring(6, 3)}-${phone.substring(6, 8)}-${phone.substring(phone.length - 2)}` : null
    },
    referralUrl() {
      return this.optionsLocal.referralCode ? `${this.baseURL.protocol}//${this.baseURL.host}/p${this.optionsLocal.referralCode}` : null
    },
    variant() {
      if (!this.userMotivationLocal) {
        return 'warning'
      } if (this.userMotivationLocal.status && (this.userMotivationLocal.status === 'denial' || this.userMotivationLocal.status === 'forbidden')) {
        return 'danger'
      }
      return 'success'
    },
    motivationBonusLocal() {
      return this.motivationBonus
    },
    userMotivationLocal() {
      return this.userMotivation
    },
  },
  async mounted() {
    await this.getData()
    this.fileAvatar = null
    this.previewEl = this.optionsLocal.avatar ? this.optionsLocal.avatar.path : null
  },
  methods: {
    async resetForm() {
      await this.getData()
      this.fileAvatar = null
      this.previewEl = this.optionsLocal.avatar ? this.optionsLocal.avatar.path : null
    },
    async resetAvatar() {
      await this.getData()
      this.fileAvatar = null
      this.previewEl = this.optionsLocal.avatar ? this.optionsLocal.avatar.path : null
    },
    clearAvatar() {
      this.optionsLocal.avatar = null
      this.fileAvatar = null
      this.previewEl = null
    },
    // eslint-disable-next-line consistent-return
    async alertDismissed() {
      try {
        const id = this.userMotivationLocal ? this.userMotivationLocal.id : null
        if (!id) {
          return false
        }
        const param = new FormData()
        param.append('hide', '1')
        const result = await usersMotivationRepository.update(id, param)
        if (!result) {
          throw new Error('Ошибка формирования данных')
        }
        return true
      } catch (error) {
        this.$store.commit('app/SET_ERROR', error)
      }
    },
    async getData() {
      try {
        this.isLoadingLocal = true
        const userId = getUserId()
        const { general } = await userRepository.getUserProfile(userId)
        this.optionsLocal = general || {}
        this.isLoadingLocal = false
      } catch (error) {
        this.optionsLocal = {}
        this.isLoadingLocal = false
        this.$store.commit('app/SET_ERROR', error)
      }
    },
    async saveForm() {
      try {
        const success = await this.$refs.generalForm.validate()
        if (!success) {
          throw new Error('Ошибка заполнения формы.')
        }
        this.isLoadingLocal = true
        const userId = getUserId()

        const param = new FormData()
        param.append('firstName', this.optionsLocal.firstName)
        param.append('lastName', this.optionsLocal.lastName)
        param.append('email', this.optionsLocal.email)
        param.append('fileAvatar', this.fileAvatar)
        param.append('avatar', this.optionsLocal.avatar ? this.optionsLocal.avatar.id : null)

        const { general } = await userRepository.updateUserData(userId, param) || {}

        this.$emit('refresh')
        this.optionsLocal = JSON.parse(JSON.stringify(general))
        this.fileAvatar = null
        this.previewEl = this.optionsLocal.avatar ? this.optionsLocal.avatar.path : null

        this.isLoadingLocal = false
        this.$store.commit('app/SET_MESSAGE', 'Данные аккаунта успешно обновлены')
      } catch (error) {
        this.isLoadingLocal = false
        this.$store.commit('app/SET_ERROR', error)
        this.$refs.generalForm.setErrors(`${error}`)
      }
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const fileAvatar = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, (data, file) => {
      previewEl.value = data
      fileAvatar.value = file
    })

    return {
      refInputEl,
      previewEl,
      fileAvatar,
      inputImageRenderer,
    }
  },
}
</script>
<style scoped>
.skeleton-avatar .b-skeleton-img {
  border-radius: 0.357rem !important;
}
.b-skeleton-button {
  padding: 0.486rem 1rem;
  border-radius: 0.358rem;
  float: left;
  width: 95px;
}
.skeleton-button-avatar .b-skeleton-text {
  margin-top: 8px;
  margin-bottom: 0;
}
.skeleton-form-group .b-skeleton-text {
  margin-bottom: 0.45rem;
}

.phone-input {
    padding: 0.438rem 1rem;
    border: #d8d6de solid 1px;
    border-radius: 0.357rem;
    height: 2.714rem;
    line-height: 1.45;
}
.media-aside {
  height: 80px;
  width: 80px;
  line-height: 80px;
}
.img-fluid {
  max-width: 80px!important;
}
.b-avatar {
  width: 80px;
  height: 80px;
}
</style>
